import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Provider} from "react-redux";
import {store, persistor} from "./store";
import { PersistGate } from 'redux-persist/integration/react'
import ReactDOM from 'react-dom';
import { MaterialUIControllerProvider } from "context";
import { injectStore } from "api/axiosPrivate";

import './index.css';
import * as serviceWorker from './serviceWorker';

import SplashScreen  from "components/splash-screens/ScreenLoader";

injectStore(store);

const App = lazy(() => import('./App'));
const AdminApp = lazy(() => import('./dashboard/App'));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
          <MaterialUIControllerProvider>
              <Suspense fallback={<SplashScreen />}>
                <Routes>
                  <Route path="/*" element={<App/>}/>
                  <Route path="/dashboard/*" element={<AdminApp/>}/>
                </Routes>
              </Suspense>
          </MaterialUIControllerProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
 ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
