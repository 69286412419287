
import { setCredentials } from "context/auth/authSlice";
import axios,{axiosPrivate} from "./http-common";

let store

export const injectStore = _store => {
  store = _store
}
const refresh  =  async () => {
    const response = await axios.get('/refresh', {
        withCredentials: true
    });
    const result = response.data;
    if(result){
        const {accessToken} = result;
        store.dispatch(setCredentials({...result}));
        return accessToken;
    }
    return false;
}

export default function axiosPrivateInstance()  {

    const requestIntercept = axiosPrivate.interceptors.request.use(
        config => {
            let authToken ;
            if (!config.headers.Authorization) {
                // get the JWT token out of it
                // (obviously depends on how your store is structured)
                const state = store.getState();
                authToken = state.auth.accessToken;
                /* eslint-disable */ 
                config.headers.Authorization = `Bearer ${authToken}`;
                
            }

            return config;
        }, (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
        response => response,
        async (error) => {
            const prevRequest = error?.config;
            if (error?.response?.status === 403 && !prevRequest?.sent) {
                prevRequest.sent = true;
                const newAccessToken = await refresh();
                if(newAccessToken !== false ){
                    prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
            }
            return Promise.reject(error);
        }
    );
    const removeAxiosPrivateIntercept = function (){
        axiosPrivate.interceptors.request.eject(requestIntercept);
        axiosPrivate.interceptors.response.eject(responseIntercept);
        return axiosPrivate;
    }
   
    return  { axiosPrivate, removeAxiosPrivateIntercept } ;
}

