import { createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
    name : "page",
    initialState : {
        progress :0,
        content : {}
    },
    reducers:{
        setPageSliceContent  : (state, action )=>{      
            const content = action.payload;
             /* eslint-disable */
            state.content = content;
        },
        uploadProgressUpdated : (state, action )=>{
            /* eslint-disable */
           state.progress = action.payload
        },
        setCancelSource : (state, action )=>{
            /* eslint-disable */
           state.cancelSource = action.payload
        },
    }

})
 /* eslint-disable */
export const selectCurrentPage    = state => state.page.content;
export const selectCurrentProgress = state => state.page.progress;

export const { setPageSliceContent, uploadProgressUpdated } = pageSlice.actions;
export default pageSlice.reducer;

