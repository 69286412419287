import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name : "auth",
    initialState : { 
        user : null, 
        accessToken :  null,
        persist : JSON.parse(localStorage.getItem("persist")) || false,
        showErrors : {
            code:"error",
            msg: null,
            icon: "info",
            type: "alert",
            action:null,
            title:"Dashboard message",
            bgWhite : true
        },
        confirm:{
            type: null,
            data: null
        }
    },
    reducers:{
        setCredentials  : (state, action )=>{      
            const {user, accessToken} = action.payload;
             /* eslint-disable */
            state.user = user;
             /* eslint-disable */
            state.accessToken = accessToken;
        },
         /* eslint-disable */
        logout : (state, action )=>{
            state.user = null;
            state.accessToken = null;
            state.showErrors =  {
                ...state.showErrors,
                msg  : "Siging out with success!",
                icon : "done",
                code : "success",
                type : "taost",
                action : null
            };
        },
        setError :  (state, action )=>{
             /* eslint-disable */
            state.showErrors = {...state.showErrors, ...action.payload}
        },
        setConfirm :  (state, action )=>{
            /* eslint-disable */
           state.confirm = {...state.confirm, ...action.payload}
       }
    }

})
 /* eslint-disable */
export const selectCurrentUser  = state => state.auth.user;
 /* eslint-disable */
export const selectCurrentToken = state => state.auth.accessToken;
 /* eslint-disable */
export const selectCurrentPersist = state => state.auth.persist;
/* eslint-disable */
export const selectCurrentErrors = state => state.auth.showErrors;
/* eslint-disable */
export const selectCurrentConfirm = state => state.auth.confirm;

export const { setCredentials,logout, setError, setConfirm, getAccessToken } = authSlice.actions;
export default authSlice.reducer;

